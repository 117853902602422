const publicFacilityUrl = `/gateway/hc-external/szdz/outApi/publicFacility/page`;
const publicFacilityDetailUrl = `/gateway/hc-external/szdz/outApi/publicFacility/detail`;

// 查询 - 设施/服务 类别 - 列表
const getSsfwListUrl = `/gateway/hc-serve/manage/publicFacilitiesInfo/getFacilitiesServiceType`;

// 查询 - 点位 - 列表
const getSqyztDwListUrl = `/gateway/hc-serve/mini/publicFacilitiesInfo/getPublicFacilitiesInfoList`;

// 查询 - 点位 - 详情
const getSqyztDwDetailUrl = `/gateway/hc-serve/mini/publicFacilitiesInfo/getDetailsById`;

export {
  publicFacilityUrl,
  publicFacilityDetailUrl,
  getSsfwListUrl,
  getSqyztDwListUrl,
  getSqyztDwDetailUrl,
};