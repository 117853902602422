const securityJsCode = '14e8f6657d33d80943c81fd846b0735b';

//地图数据
const mapData = [
  {
    id: 1,
    longitude: "121.87597600",
    latitude: "29.47409500",
    name: "金秋社区党群服务中心",
    type: "社区综合服务中心/党群服务中心",
    address: "宁波市象山县丹东街道新华路140弄6号",
    area: "800㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 2,
    longitude: "121.87287200",
    latitude: "29.47484300",
    name: "博雅艺术幼儿园",
    type: "幼儿园",
    address: "宁波市象山县丹东街道金鹰路41弄33号",
    area: "747㎡",
    nature: "企业所有",
    status: "现有",
  },
  {
    id: 3,
    longitude: "121.87592000",
    latitude: "29.47414000",
    name: "金秋社区文化活动中心",
    type: "社区文化活动站（含城市书房/共享书屋/社区礼堂）",
    address: "宁波市象山县丹东街道新华路140弄6号",
    area: "100㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 4,
    longitude: "121.87050800",
    latitude: "29.47640800",
    name: "象山县图书馆老年分馆",
    type: "社区文化活动站（含城市书房/共享书屋/社区礼堂）",
    address: "宁波市象山县丹东街道金声路7号",
    area: "208㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 5,
    longitude: "121.87499000",
    latitude: "29.47295000",
    name: "生肖公园",
    type: "社区公园",
    address: "宁波市象山县步北路象山步行街",
    area: "400㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 6,
    longitude: "121.87503000",
    latitude: "29.47278000",
    name: "步南路公共卫生间",
    type: "独立式公共卫生间",
    address: "宁波市象山县步南街40号对面",
    area: "20㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 7,
    longitude: "121.87503000",
    latitude: "29.47278000",
    name: "新华路公共卫生间",
    type: "独立式公共卫生间",
    address: "宁波市象山县东塘山路47号附近",
    area: "25㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 8,
    longitude: "121.86958000",
    latitude: "29.47428000",
    name: "天安路公共卫生间",
    type: "独立式公共卫生间",
    address: "宁波市象山县天安路23号附近",
    area: "20㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 9,
    longitude: "121.87309000",
    latitude: "29.47624000",
    name: "尚秋小区垃圾分类投放点",
    type: "分类垃圾投放点",
    address: "宁波市象山县塔山路和新华路的交界口",
    area: "--",
    nature: "国有",
    status: "现有",
  },
  {
    id: 10,
    longitude: "121.87287000",
    latitude: "29.47611000",
    name: "尚秋小区健身路径",
    type: "室外健身器材（健身路径）",
    address: "宁波市象山县塔山路和新华路的交界口",
    area: "--",
    nature: "国有",
    status: "现有",
  },
  {
    id: 11,
    longitude: "121.87211000",
    latitude: "29.47620000",
    name: "社区食堂",
    type: "社区食堂",
    address: "金秋社区新建邻里中心内",
    area: "--",
    nature: "国有",
    status: "规划",
  },
  {
    id: 12,
    longitude: "121.87214000",
    latitude: "29.47616000",
    name: "社区照护驿站",
    type: "托育机构/社区照护驿站",
    address: "金秋社区新建邻里中心内",
    area: "138㎡",
    nature: "国有",
    status: "规划",
  },
  {
    id: 13,
    longitude: "121.87151000",
    latitude: "29.47543000",
    name: "社区健康绿道",
    type: "健身步道",
    address: "宁波市象山县塔山路沿线及周边",
    area: "--",
    nature: "国有",
    status: "规划",
  },
  {
    id: 14,
    longitude: "121.87151000",
    latitude: "29.47543000",
    name: "社区健康绿道",
    type: "健身步道",
    address: "宁波市象山县塔山路沿线及周边",
    area: "--",
    nature: "国有",
    status: "规划",
  },
  {
    id: 15,
    longitude: "121.87213000",
    latitude: "29.47622000",
    name: "幸福学堂",
    type: "四点半学堂/幸福学堂",
    address: "金秋社区邻里中心",
    area: "60㎡",
    nature: "国有",
    status: "规划",
  },
  {
    id: 16,
    longitude: "121.87356400",
    latitude: "29.47310200",
    name: "南门村文化礼堂",
    type: "社区文化活动站（含城市书房/共享书屋/社区礼堂）",
    address: "宁波市象山县丹东街道天安路119弄33号",
    area: "128㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 17,
    longitude: "121.87506000",
    latitude: "29.47295000",
    name: "海棠苑健身路径",
    type: "室外健身器材（健身路径）",
    address: "宁波市象山县丹东街道海棠苑小区",
    area: "--",
    nature: "国有",
    status: "现有",
  },
  {
    id: 18,
    longitude: "121.87382000",
    latitude: "29.47546000",
    name: "金秋住宅区体育小公园",
    type: "社区公园",
    address: "宁波市象山县丹东街道金秋住宅区",
    area: "60㎡",
    nature: "集体所有",
    status: "现有",
  },
  {
    id: 19,
    longitude: "121.87593000",
    latitude: "29.47416000",
    name: "金秋社区儿童之家",
    type: "四点半学堂/幸福学堂",
    address: "宁波市象山县丹东街道新华路140弄6号",
    area: "25㎡",
    nature: "国有",
    status: "现有",
  },
  {
    id: 20,
    longitude: "121.87386000",
    latitude: "29.47549000",
    name: "金秋小区健身路径",
    type: "室外健身器材（健身路径）",
    address: "宁波市象山县塔山路48号",
    area: "--",
    nature: "国有",
    status: "现有",
  },
  {
    id: 21,
    longitude: "121.87212000",
    latitude: "29.47616000",
    name: "共享书屋",
    type: "社区文化活动站（含城市书房/共享书屋/社区礼堂）",
    address: "金秋社区新建邻里中心内",
    area: "88㎡",
    nature: "国有",
    status: "规划",
  },
  {
    id: 22,
    longitude: "121.87213000",
    latitude: "29.47621000",
    name: "社区综合活动室",
    type: "社区文化活动站（含城市书房/共享书屋/社区礼堂）",
    address: "金秋社区新建邻里中心内",
    area: "120㎡",
    nature: "国有",
    status: "规划",
  },
  {
    id: 23,
    longitude: "121.87214000",
    latitude: "29.47619000",
    name: "党群服务中心",
    type: "社区综合服务中心/党群服务中心",
    address: "金秋社区新建邻里中心内",
    area: "90㎡",
    nature: "国有",
    status: "规划",
  },
  {
    id: 24,
    longitude: "121.86923000",
    latitude: "29.47519000",
    name: "社区卫生服务站",
    type: "社区卫生服务站&智慧健康站",
    address: "金秋社区新建邻里中心内",
    area: "150㎡",
    nature: "国有",
    status: "规划",
  },
  {
    id: 25,
    longitude: "121.87214000",
    latitude: "29.47623000",
    name: "邻里中心",
    type: "社区文化活动站（含城市书房/共享书屋/社区礼堂）",
    address: "--",
    area: "1500㎡",
    nature: "国有",
    status: "规划",
  },
];
export { securityJsCode, mapData };
