<template>
  <div class="a-community-map">
    <div id="a_map" class="a-map"></div>
    <div :class="['search-content', { 'btm-no-rad': selectBoxShow }]" @click="searchBoxOnClick">
      <van-search
        v-model="keyWord"
        :show-action="false"
        placeholder="请输入"
        shape="round"
        background="transparent"
        @search="onSearch"
        @focus="selectBoxShow = false"
        @blur="selectBoxShow = false"
      />
      <div class="search-btn" @click="onSearch">搜索</div>
      <div class="select-list" v-show="selectBoxShow">
        <div class="select-item" v-for="(item, index) in selectList" :key="'xx' + index">{{ item.name }}</div>
      </div>
    </div>
    <div :class="['point-type-list', { hidden: !pointTypeShow }]">
      <div
        :class="['point-type-item', { active: item.typeCode === pointSelectedType }]"
        v-for="(item, index) in pointTypeList"
        :key="'dwlx' + index"
        @click="pointTypeOnClick(item, index)"
      >
        <img class="point-img" :src="item.typeCode === pointSelectedType ? item.iconClick : item.iconImg" />
        <div class="label-text">{{ item.typeName }}</div>
      </div>
    </div>
    <!-- <div :class="['point-type-box', { hidden: !pointTypeShow }]">
      <van-radio-group v-model="pointSelectedType" direction="horizontal" icon-size="14px" @change="pointTypeOnChange">
        <van-radio name="all">全部</van-radio>
        <van-radio v-for="(item, index) in pointTypeList" :key="'dwlx' + index" :name="item.typeCode">{{ item.typeName }}</van-radio>
      </van-radio-group>
    </div> -->
    <!-- https://picsum.photos/id/684/600/400 -->
    <div :class="['point-info-content', { hidden: !pointInfoShow }]">
      <van-swipe class="" :autoplay="300000" indicator-color="#FDC146">
        <van-swipe-item v-for="(item, index) in pointsDetailList" :key="'dwxq' + index">
          <div class="point-info-item">
            <div class="location-picture">
              <img class="location-img" :src="item.facilitiesImage" @click="showPicture(item.facilitiesImages)" />
            </div>
            <div class="location-info">
              <div class="title-text">{{ item.name }}</div>
              <div class="address-text">{{ item.address }}</div>
              <div class="hand-content">
                <div class="hand-item" @click="goHereOnClick(item, index)">
                  <img class="icon" src="./img/icon_dzq.png" />
                  <div class="label-text">到这去</div>
                </div>
                <div v-if="item.isUseActivity === 1 && item.activitySpace" class="hand-item" @click="cjhdOnClick(item, index)">
                  <img class="icon" src="./img/icon_cjhd.png" />
                  <div class="label-text">参加活动</div>
                </div>
                <div v-if="item.isUseSpace === 1 && item.reservationSpace" class="hand-item" @click="yykjOnClick(item, index)">
                  <img class="icon" src="./img/icon_yykj.png" />
                  <div class="label-text">预约空间</div>
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <!-- <div class="point-info-item">
        <div class="location-picture">
          <img class="location-img" :src="pointDetail.facilitiesImage" @click="showPicture(pointDetail.facilitiesImages)" />
        </div>
        <div class="location-info">
          <div class="title-text">{{ pointDetail.name }}</div>
          <div class="address-text">{{ pointDetail.address }}</div>
          <div class="hand-content">
            <div class="hand-item" @click="goHereOnClick">
              <img class="icon" src="./img/icon_dzq.png" />
              <div class="label-text">到这去</div>
            </div>
            <div v-if="pointDetail.isUseActivity === 1 && pointDetail.activitySpace" class="hand-item" @click="cjhdOnClick">
              <img class="icon" src="./img/icon_cjhd.png" />
              <div class="label-text">参加活动</div>
            </div>
            <div v-if="pointDetail.isUseSpace === 1 && pointDetail.reservationSpace" class="hand-item" @click="yykjOnClick">
              <img class="icon" src="./img/icon_yykj.png" />
              <div class="label-text">预约空间</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getSsfwListUrl, getSqyztDwListUrl, getSqyztDwDetailUrl } from './api.js';
import pointIconDetault from './img/default_hot.png';
import typeIcon from './img/default.png';
import typeIconActive from './img/default_active.png';
import { securityJsCode } from './mapData.js';
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import AMapLoader from '@amap/amap-jsapi-loader';
import { ImagePreview, Swipe, SwipeItem } from 'vant';

window._AMapSecurityConfig = { securityJsCode };

export default {
  name: 'aCommunityMap',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  props: {},
  model: {},
  data() {
    return {
      keyWord: '',
      selectBoxShow: false,
      map: null,
      cluster: null,
      geocoder: null,
      walking: null,
      driving: null,
      geolocation: null,
      longitude: '', // 121.869251    121.869148
      latitude: '', // 29.476826    29.4749
      currentLon: '',
      currentLat: '',
      pointAllList: [],
      pointShowList: [],
      pointSelectedType: '',
      pointTypeShow: true,
      pointTypeIconMap: {},
      pointTypeList: [],
      pointInfoShow: false,
      pointsDetailList: [],
      pointDetail: {
        id: null,
        name: '',
        source: null,
        facilitiesName: '',
        facilitiesType: '',
        facilitiesTypeName: '',
        serviceType: '',
        serviceTypeName: '',
        longitude: '',
        latitude: '',
        address: '',
        hotImages: '',
        facilitiesImages: '',
        facilitiesImage: '',
        isUseActivity: null,
        activitySpace: '',
        isUseSpace: null,
        reservationSpace: '',
        isDelete: null,
        tenantId: '',
        insertTime: '',
        insertUser: null,
        updateTime: '',
        updateUser: null
      }
    };
  },
  computed: {
    ...mapState(['tenantId']),
    selectList() {
      if (this.keyWord) {
        return this.pointShowList.filter(item => item.name.includes(this.keyWord));
      } else {
        return this.pointShowList;
      }
    }
  },
  watch: {},
  created() {
    this.$axios.get(getSsfwListUrl).then(res => {
      if (res && res.code === 200) {
        res.data = res.data || {};
        res.data[2] = Array.isArray(res.data[2]) ? res.data[2] : [];
        res.data[2].forEach(item => {
          item.iconImg = item.iconImg || typeIcon;
          item.iconClick = item.iconClick || typeIconActive;
          item.hotImg = item.hotImg || pointIconDetault;
        });
        this.pointTypeList = res.data[2];
        this.pointTypeIconMap = Object.fromEntries(
          this.pointTypeList.map(item => [item.typeCode, { iconImg: item.iconImg, iconClick: item.iconClick, hotImg: item.hotImg }])
        );
      }
    });
    this.$axios.get(getSqyztDwListUrl, { params: { tenantId: this.tenantId, curPage: 1, pageSize: 99, serviceType: '' } }).then(res => {
      if (res && res.code === 200) {
        res.data = Array.isArray(res.data) ? res.data : [];
        this.pointAllList = res.data.map(item => ({ name: item.facilitiesName, address: '', ...item }));
        this.pointShowList = this.pointAllList;
        this.$nextTick(() => {
          this.initAMap();
        });
      }
    });
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    searchBoxOnClick() {
      this.pointInfoShow = false;
      this.pointTypeShow = true;
    },
    onSearch() {
      const list = this.pointShowList.filter(item => item.name.includes(this.keyWord));
      const result = list[0] || {};
      if (result.longitude && result.latitude) {
        this.walking && this.walking.clear();
        this.driving && this.driving.clear();
        this.map.setCenter([result.longitude, result.latitude]);
        this.map.setZoom(17);
      }
    },
    initAMap() {
      const { longitude, latitude } = this.pointShowList[0] || {};
      this.longitude = longitude || '120.152575';
      this.latitude = latitude || '30.266619';
      AMapLoader.reset();
      AMapLoader.load({
        key: '6ccc57a1965684bcf1d56bf846608b44', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.Scale',
          'AMap.ToolBar',
          'AMap.ControlBar',
          'AMap.Geocoder',
          'AMap.Marker',
          'AMap.CitySearch',
          'AMap.Geolocation',
          'AMap.AutoComplete',
          'AMap.InfoWindow',
          'AMap.Walking',
          'AMap.Driving',
          'AMap.MarkerCluster'
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(AMap => {
          // // 获取到作为地图容器的DOM元素，创建地图实例
          this.map = new AMap.Map('a_map', {
            //设置地图容器id
            resizeEnable: true,
            viewMode: '2D', // 使用3D视图
            showLabel: false, // 不显示地图文字标记
            zoomEnable: true, // 地图是否可缩放，默认值为true
            dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
            doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
            zoom: 17, //初始化地图级别
            center: [this.longitude, this.latitude] // 初始化中心点坐标 广州
            // mapStyle: "amap://styles/darkblue", // 设置颜色底层
          });
          // this.map.on('click', event => {
          //   this.pointInfoShow = false;
          //   this.pointTypeShow = true;
          // });
          this.map.clearMap();
          this.geocoder = new AMap.Geocoder({
            city: '全国'
          });

          const points = this.pointShowList.map(item => ({ ...item, lnglat: [item.longitude, item.latitude] }));
          // 添加点聚合
          this.cluster = new AMap.MarkerCluster(this.map, points, {
            gridSize: 60, // 设置网格像素大小
            maxZoom: 20,
            renderMarker: context => {
              const valueData = context.data[0];
              var content = `
                <div style="white-space:nowrap;position:relative;font-size:14px;transform: translate(-50%, -50%);background-color: transparent;">
                  ${valueData.name}
                  <img style="position:absolute;left:50%;top:0%;width:22px;height:30px;transform: translate(-50%, -30px);" src="${
                    valueData.hotImages ? valueData.hotImages : this.pointTypeIconMap[valueData.serviceType]?.hotImg || pointIconDetault
                  }">
                </div>`;
              var offset = new AMap.Pixel(0, 0);
              context.marker.setContent(content);
              context.marker.setOffset(offset);
              // context.marker.on('click', event => {
              //   this.pointOnClick(event, valueData);
              // });
            }
          });
          this.cluster.on('click', event => {
            this.pointsOnClick(event, event.clusterData);
          });

          // 创建步行导航实例
          this.walking = new AMap.Walking({
            map: this.map // 指定使用的地图
          });
          this.driving = new AMap.Driving({
            map: this.map // 指定使用的地图
          });
          this.geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, // 是否使用高精度定位，默认:true
            timeout: 10000 // 超过10秒后停止定位，默认：5s
          });
          this.map.setCenter([this.longitude, this.latitude]);
        })
        .catch(errInfo => {
          console.error('地图错误 => ', errInfo);
        });
    },
    pointOnClick: debounce(function (event, value) {
      console.log('点-event => ', event);
      console.log('点-value => ', value);
      Object.keys(this.pointDetail).forEach(k => {
        if (k === 'facilitiesImage') {
          this.pointDetail.facilitiesImage = typeof value.facilitiesImages === 'string' ? value.facilitiesImages.split(',')[0] : '';
        } else {
          this.pointDetail[k] = value[k] ?? '';
        }
      });
      this.pointsDetailList = [this.pointDetail];
      this.pointTypeShow = false;
      this.pointInfoShow = true;
    }, 100),
    pointsOnClick: debounce(function (event, list) {
      console.log('点集合-event => ', event);
      console.log('点集合-list => ', list);
      const keys = Object.keys(this.pointDetail);
      const pointsDetailList = [];
      list.forEach(item => {
        const detail = {};
        keys.forEach(k => {
          if (k === 'facilitiesImage') {
            detail.facilitiesImage = typeof item.facilitiesImages === 'string' ? item.facilitiesImages.split(',')[0] : '';
          } else {
            detail[k] = item[k] ?? '';
          }
        });
        pointsDetailList.push(detail)
      });
      this.pointsDetailList = pointsDetailList;
      this.pointTypeShow = false;
      this.pointInfoShow = true;
    }, 100),
    pointTypeOnChange(value) {
      this.walking && this.walking.clear();
      this.walking && this.driving.clear();
      this.pointShowList = this.pointAllList.filter(
        item => item.serviceType === this.pointSelectedType || this.pointSelectedType === 'all'
      );
      const points = this.pointShowList.map(item => ({ ...item, lnglat: [item.longitude, item.latitude] }));
      if (this.cluster) {
        this.cluster.setData(points);
      }
      const { longitude, latitude } = this.pointShowList[0] || {};
      this.longitude = longitude;
      this.latitude = latitude;
      this.map.setCenter([this.longitude, this.latitude]);
      this.map.setZoom(17);
    },
    pointTypeOnClick(value, index) {
      if (Object.is(this.pointSelectedType, value.typeCode)) {
        this.pointSelectedType = '';
        this.walking && this.walking.clear();
        this.walking && this.driving.clear();
        this.pointShowList = this.pointAllList;
        const points = this.pointShowList.map(item => ({ ...item, lnglat: [item.longitude, item.latitude] }));
        if (this.cluster) {
          this.cluster.setData(points);
        }
        // const { longitude, latitude } = this.pointShowList[0] || {};
        // this.longitude = longitude;
        // this.latitude = latitude;
        // this.map.setCenter([this.longitude, this.latitude]);
        // this.map.setZoom(17);
      } else {
        this.pointSelectedType = value.typeCode;
        this.walking && this.walking.clear();
        this.walking && this.driving.clear();
        this.pointShowList = this.pointAllList.filter(
          item => item.serviceType === this.pointSelectedType || this.pointSelectedType === 'all'
        );
        const points = this.pointShowList.map(item => ({ ...item, lnglat: [item.longitude, item.latitude] }));
        if (this.cluster) {
          this.cluster.setData(points);
        }
        const { longitude, latitude } = this.pointShowList[0] || {};
        this.longitude = longitude;
        this.latitude = latitude;
        this.map.setCenter([this.longitude, this.latitude]);
        this.map.setZoom(17);
      }
    },
    goHereOnClick(value, index) {
      let _this = this;
      this.geolocation.getCurrentPosition((status, result) => {
        console.log(status, '定位-result => ', result);
        if (status == 'complete') {
          this.currentLon = result.position.getLng();
          this.currentLat = result.position.getLat();
          let startLngLat = [this.currentLon, this.currentLat]; //起始点坐标
          let endLngLat = [value.longitude, value.latitude]; //终点坐标
          if (this.walking) {
            this.walking.search(startLngLat, endLngLat, function (status, result) {
              //status：complete 表示查询成功，no_data 为查询无结果，error 代表查询错误
              //查询成功时，result 即为对应的驾车导航信息
              if (status == 'error' && result == 'OVER_DIRECTION_RANGE') {
                _this.driving.search(startLngLat, endLngLat, (ostatus, oresult) => {
                  console.log(ostatus, oresult, '00');
                });
              }
            });
          }
        } else {
          this.$toast('获取当前定位失败')
        }
      });
    },
    cjhdOnClick(value, index) {
      this.$router.push({
        name: 'applyActivity',
        query: { spaceIds: value.activitySpace }
      });
    },
    yykjOnClick(value, index) {
      this.$router.push({
        name: 'areaAppoint',
        query: { spaceIds: value.reservationSpace }
      });
    },
    showPicture(src) {
      ImagePreview(typeof src === 'string' ? src.split(',') : []);
    }
  }
};
</script>

<style lang="less" scoped>
// constant(safe-area-inset-*)
// env(safe-area-inset-*)
// safe-area-inset-top
// safe-area-inset-right
// safe-area-inset-bottom
// safe-area-inset-left
.a-community-map {
  box-sizing: border-box;
  width: 100vw;
}
.a-map {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  height: calc(100vh - constant(safe-area-inset-bottom));
  height: calc(100vh - env(safe-area-inset-bottom));
}
.search-content {
  box-sizing: border-box;
  width: calc(100% - 60px);
  position: fixed;
  left: 30px;
  top: 20px;
  height: 60px;
  border-radius: 30px;
  background-color: #ffffff;
  .van-search {
    padding: 0px;
    .van-search__content {
      background-color: #ffffff;
      padding-right: 120px;
    }
  }
  .search-btn {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 120px;
    height: 60px;
    font-size: 20px;
    background: linear-gradient(180deg, #ffb300 0%, #ffe390 100%);
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .select-list {
    box-sizing: border-box;
    position: absolute;
    left: 0px;
    top: 60px;
    width: 100%;
    max-height: 200px;
    background-color: #ffffff;
  }
  &.btm-no-rad {
    border-radius: 30px 30px 0px 0px;
  }
}
.point-type-list {
  box-sizing: border-box;
  position: fixed;
  left: 0px;
  bottom: 0px;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  width: 100vw;
  height: 160px;
  background-color: #ffffff;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  transition: bottom 0.3s ease 0.3s;
  .point-type-item {
    flex-shrink: 0;
    box-sizing: border-box;
    text-align: center;
    margin-right: 20px;
    min-width: calc((100% / 7) - 20px);
    .point-img {
      box-sizing: border-box;
      width: 60px;
      height: 60px;
    }
    .label-text {
      font-size: 20px;
      white-space: nowrap;
      color: rgba(51, 51, 51, 0.85);
    }
    &:last-child {
      margin-right: 0px;
    }
    &.active {
      .label-text {
        color: #ff5b00;
        font-weight: 600;
      }
    }
  }
  &.hidden {
    bottom: -160px;
    bottom: calc(-160px - constant(safe-area-inset-bottom));
    bottom: calc(-160px - env(safe-area-inset-bottom));
    transition: bottom 0.3s ease 0s;
  }
}
// .point-type-box {
//   box-sizing: border-box;
//   position: fixed;
//   left: 0px;
//   bottom: 0px;
//   bottom: constant(safe-area-inset-bottom);
//   bottom: env(safe-area-inset-bottom);
//   width: 100vw;
//   height: 160px;
//   background-color: #ffffff;
//   padding: 20px 30px;
//   display: flex;
//   align-items: flex-start;
//   overflow-x: hidden;
//   overflow-y: auto;
//   transition: bottom 0.3s ease 0.3s;
//   .van-radio-group {
//     align-items: center;
//     min-height: 100%;
//   }
//   /deep/ .van-radio__label {
//     font-size: 16px;
//   }
//   &.hidden {
//     bottom: -160px;
//     bottom: calc(-160px - constant(safe-area-inset-bottom));
//     bottom: calc(-160px - env(safe-area-inset-bottom));
//     transition: bottom 0.3s ease 0s;
//   }
// }
.point-info-content {
  box-sizing: border-box;
  position: fixed;
  left: 30px;
  bottom: 20px;
  bottom: calc(20px + constant(safe-area-inset-bottom));
  bottom: calc(20px + env(safe-area-inset-bottom));
  width: calc(100vw - 60px);
  height: 220px;
  background-color: #ffffff;
  transition: bottom 0.3s ease 0.3s;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 8px rgba(66, 114, 153, 0.1);
  .point-info-item {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px 20px 40px 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
  }
  .location-picture {
    box-sizing: border-box;
    width: 160px;
    height: 160px;
    border-radius: inherit;
    margin-right: 20px;
    .location-img {
      box-sizing: border-box;
      flex-shrink: 0;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .location-info {
    box-sizing: border-box;
    flex-grow: 1;
    width: calc(100% - 180px);
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .title-text {
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      font-size: 26px;
      height: 34px;
      line-height: 34px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
    }
    .address-text {
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 10px;
    }
    .hand-content {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      height: 44px;
      .hand-item {
        box-sizing: border-box;
        flex-shrink: 0;
        background-color: #f7f7f7;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 12px;
        margin-right: 10px;
        .icon {
          box-sizing: border-box;
          width: 24px;
          margin-right: 10px;
        }
        .label-text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
  &.hidden {
    bottom: -230px;
    bottom: calc(-230px - constant(safe-area-inset-bottom));
    bottom: calc(-230px - env(safe-area-inset-bottom));
    transition: bottom 0.3s ease 0s;
  }
}

/deep/ .amap-logo {
  display: none !important;
}
/deep/ .amap-copyright {
  display: none !important;
}
/deep/ .van-swipe__indicator {
  background-color: #777777;
}
</style>
